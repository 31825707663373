//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {productSubCategoryCodes} from "@/frontend/enums/productCategoryCodes";
import ContactUs from '@/frontend/components/common/contact-us'

export default {
  name: 'Home',
  components: {
    ContactUs,
  },
  data: () => {
    return {
      shopByCollection: [],
      shopByCategory: [
        {
          name: 'View All',
          code: '',
        },
        {
          name: 'Bangle & Bracelets',
          code: productSubCategoryCodes.BanglesBracelets,
        },
        {
          name: 'Earrings',
          code: productSubCategoryCodes.Earrings,
        },
        {
          name: 'Necklace & Pendants',
          code: productSubCategoryCodes.NecklacesPendants,
        },
        {
          name: 'Rings',
          code: productSubCategoryCodes.Rings,
        },
      ],
      services: [
        {
          title: 'About Us',
          link: '/our-story',
        },
        {
          title: 'Community Responsibility',
          link: '/community-responsibility',
        },
        {
          title: 'Contact Us',
          link: ''
        }
      ],
      showPrivacyContent: false,
      privacyContent: '',
      showContactUs: false,
    }
  },
  async mounted(){
    const responseCollection = await this.$axios.$get('productcategories/categorieswithchild/ShopCollection')
    if (responseCollection.hasErrors) {
      this.$toast.error(responseCollection.errorMessages.join('</br>'))
    } else {
      this.shopByCollection = this.collections = _.orderBy(responseCollection.value, 'name', 'asc')
      this.shopByCollection.unshift({name: "View All", code: ''})
    }
    /*
    const response = await this.$axios.$get('productcategories/categorieswithchild/Category')
    if (response.hasErrors) {
      this.$toast.error(response.errorMessages.join('</br>'))
    } else {
      this.shopByCategory = response.value
    }*/
    let content = (await this.$axios.get(`/contents/privacy-statement`)).data;
    const vueObj = {}
    Object.assign(vueObj, content.details)
    if (vueObj.sections[1].text && vueObj.sections[1].text.fieldValue) {
      this.privacyContent = vueObj.sections[1].text.fieldValue.value
    }
  },
  methods: {
    clickService(title){
      switch(title){
        case 'Contact Us':
          this.showContactUs = true
          break
      }
    },
    closeContactUsDialog(){
      this.showContactUs = false
    },
  },
}
